//React
import React from "react"

//Components
import Layout from "../components/layout"
import LoginScreen from "../components/appLogin/loginScreen"
// import LoginMarketing from "../components/appLogin/loginMarketing"

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Layout>
        <div className="container d-flex flex-grow-1">
          <div className="row d-flex flex-grow-1 flex-row justify-content-center align-items-center mt-3 mt-lg-4">
            <div
              className="col-md-6 p-3 p-lg-5 d-flex justify-content-center align-items-center align-self-stretch"
              // style={{ border: "1px solid blue" }}
            >
              <LoginScreen />
            </div>
            {/* <div
              className="d-none d-md-flex col-md-6 p-3 p-lg-5 justify-content-center align-items-center align-self-stretch"
              // style={{ border: "1px solid blue" }}
            >
              <LoginMarketing />
            </div> */}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Login
